import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import Home from "./Home";
import SharedLayout from "./SharedLayout";
import Stockshop from "./StockSpace/StockShop";
import StockNew from "./StockSpace/StockNew";
import StockPromotions from "./StockSpace/StockPromotions";
import ProductsShop from "./ProductsSpace/ProductsShop";
import About from "./about-kia";
import BookService from "./bookService";
import ContactUs from "./contactUs";

import CartFull from "./CartFull";
import StockDetails from "./StockSpace/StockDetails";
import DownloadCenter from "./downloadCenter";
import WishList from "./WishList";
import Blogs from "./Blogs/blogs";
import AllPosts from "./Blogs/all-posts";
import OnePost from "./Blogs/one-post";

import LegalNotice from "./Legal/legal-notice";
import PopiaManual from "./Legal/POPIA-manual";
import PrivacyPolicy from "./Legal/privacy-policy";
import Terms from "./Legal/terms";
import Vacancies from "./Vacancies";
import Specials from "./Specials";
import Accessories from "./Accessories";
import Parts from "./Parts";
import Service from "./Service";

import Jolion from "./Jolion";
import JolionS from "./JolionS";
import JolionHEV from "./jolionHEV";
import H6 from "./H6";
import H6GT from "./H6GT";
import H6HEV from "./H6HEV";
import H7 from "./H7";
import HavalRange from "./haval-range";

class App extends Component {
  constructor() {
    super();
    this.state = {
      stockfilters: {},
    };
  }

  setStockFilters(stockfilters) {
    this.setState({ stockfilters });
  }

  render() {
    return (
      <BrowserRouter>
        <SharedLayout stockfilters={this.setStockFilters.bind(this)}>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/products" component={ProductsShop} />
          <Route
            path="/stock"
            render={() => <Stockshop filters={this.state.stockfilters} />}
          />
          <Route
            path="/promotions"
            render={() => <StockPromotions filters={this.state.stockfilters} />}
          />
          <Route
            path="/new-vehicles"
            render={() => <StockNew filters={this.state.stockfilters} />}
          />
          <Route path="/stocko" component={StockDetails} />
          <Route path="/about-kia" component={About} />
          <Route path="/bookService" component={BookService} />
          <Route path="/Vacancies" component={Vacancies} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/Specials" component={Specials} />
          <Route path="/Accessories" component={Accessories} />
          <Route path="/Parts" component={Parts} />
          <Route path="/Service" component={Service} />
          <Route path="/downloadCenter" component={DownloadCenter} />
          <Route path="/products_cart" component={CartFull} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/wishlist" component={WishList} />
          <Route path="/stock/vehicle-details" component={Stockshop} />
          <Route path="/products/vehicle-details" component={ProductsShop} />
          <Route path="/legal-notice" component={LegalNotice} />
          <Route path="/POPIA-manual" component={PopiaManual} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms" component={Terms} />
          <Route path="/jolion" component={Jolion} />
          <Route path="/jolion-s" component={JolionS} />
          <Route path="/jolion-hev" component={JolionHEV} />
          <Route path="/h6" component={H6} />
          <Route path="/h6-gt" component={H6GT} />
          <Route path="/h6-hev" component={H6HEV} />
          <Route path="/h7" component={H7} />
          <Route path="/haval-range" component={HavalRange} />

          <Route component={OnePost} path="/:slug"></Route>
          <Route component={AllPosts} path="/all-posts"></Route>
        </SharedLayout>
      </BrowserRouter>
    );
  }
}

export default connect(null, actions)(App);
