import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Select,
  message,
  Carousel,
  BackTop,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NewCar from "../images/new-car-transparent.png";
import UsedCar from "../images/used-car-transparent.png";
import DemoCar from "../images/demo-car-transparent.png";
import ThinkImg from "../images/man-thinking.png";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../redux/actions";
function onChange(a, b, c) {
  console.log(a, b, c);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
const { Meta } = Card;
const onClickBranch = ({ key }) => {
  message.info(`Website Coming Soon!`);
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    window.updateCarousel();
    window.reRenderRangeSlider();
    var stock = this.props.stock;
    fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({
        stock: result.allStock,
      });
    });
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
    //if (
    //  this.props.wishlistproducts.filter(function (e) {
    //    return e._id === stock._id;
    //  }).length === 1
    //) {
    //  this.setState({ isInWishList: true });
    //}
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render(props, stock) {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "black",
    //    fontWeight: "bold",
    //  };
    //}
    const duplicateCheck = [];

    const vehicleRangeSettings = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <div className="wrap-body-inner desktop-view" id="NewVehicles">
          <div>
            {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
            {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          </div>
          <div
            className="row"
            style={{
              backgroundImage:
                "url(" + `/images/haval-ranges-background-image.webp` + ")",
              backgroundSize: "100vw",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              height: "100%",
            }}
          >
            {/* <h1
              style={{
                margin: "auto",
                paddingTop: "1em",
                fontSize: "30px",
                lineHeight: "0.2em",
                color: "white",
                marginBottom: "1em",
                maxWidth: "42em",
              }}
            >
              Range of Vehicles
            </h1> */}
            {/* <img
                src={`${process.env.PUBLIC_URL}/images/chery-new/vehicle-view-back.png`}
                alt=''
                style={{ width: "100%", margin: "auto", position: "absolute", top: 0, zIndex: 1 }}
              /> */}
            <Slider {...vehicleRangeSettings}>
              {/* 1st Slide Start */}
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/jolion`}>
                        <img
                          src={`/images/jolion.webp`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        JOLION Contemporary Design
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/jolion`}>
                        <div className="hover-info">
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />

                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </div>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href="/pdfs/Haval_Jolion_Brochure_October_2022.pdf"
                        target="_blank"
                      >
                        {" "}
                        <div className="hover-info">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/jolion`}>
                        <div className="hover-info">
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>

              {/* 1st Slide End */}
              {/* 2nd Slide Start */}
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/jolion-s`}>
                        <img
                          src={`/images/jolion-s.webp`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        Jolion S
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/jolion-s`}>
                        <div className="hover-info">
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </div>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href="/pdfs/haval_jolion-s_brochure.pdf"
                        target="_blank"
                      >
                        <div className="hover-info">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/jolion-s`}>
                        <div className="hover-info">
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              {/* 2nd Slide End */}
              {/* 3rd Slide Start */}
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/jolion-hev`}>
                        <img
                          src={`/images/jolion-hev.webp`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        Jolion HEV
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/jolion-hev`}>
                        <div className="hover-info">
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </div>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href="/pdfs/Haval_Jolion_HEV_Brochure.pdf"
                        target="_blank"
                      >
                        <div className="hover-info">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/jolion-hev`}>
                        <div className="hover-info">
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              {/* 3rd Slide End */}
              {/* 4th Slide Start */}
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/h6`}>
                        <img
                          src={`/images/haval-h6.webp`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        HAVAL H6
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/h6`}>
                        <div className="hover-info">
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </div>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a href="/pdfs/haval_H6_brochure.pdf" target="_blank">
                        <div className="hover-info">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/h6`}>
                        <div className="hover-info">
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              {/* 4th Slide End */}
              {/* 5th Slide Start */}
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/h6-gt`}>
                        <img
                          src={`/images/haval-h6-gt.webp`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        HAVAL H6 GT
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/h6-gt`}>
                        <div className="hover-info">
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </div>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a href="/pdfs/H6_GT_Brochure.pdf" target="_blank">
                        <div className="hover-info">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/h6-gt`}>
                        <div className="hover-info">
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              {/* 5th Slide End */}
              {/* 6th Slide Start */}
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/h6-hev`}>
                        <img
                          src={`/images/haval-h6-hev.webp`}
                          alt=""
                          style={{
                            width: "60%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        HAVAL H6 HEV
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/h6-hev`}>
                        <div className="hover-info">
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </div>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a
                        href="/pdfs/Haval-H6-HEV-Specsheet.pdf"
                        target="_blank"
                      >
                        <div className="hover-info">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/h6-hev`}>
                        <div className="hover-info">
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              {/* 6th Slide End */}
              {/* 7th Slide Start */}
              <Row>
                <Fade bottom delay={100} casacde>
                  <Col span={24}>
                    <div
                      className="hover-img "
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link className="product-img" to={`/h7`}>
                        <img
                          src={`/images/haval-h7.webp`}
                          alt=""
                          style={{
                            width: "29.5%",
                            margin: "auto",
                            marginTop: "8em",
                          }}
                        />
                      </Link>
                    </div>
                  </Col>
                </Fade>
                <div
                  style={{
                    maxWidth: "800px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Col span={24}>
                      <h1
                        style={{
                          fontSize: "35px",
                          textTransform: "none",
                          color: "white",
                          letterSpacing: "0.04em",
                        }}
                      >
                        The All New HAVAL H7
                      </h1>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: "8.5em",
                      marginBottom: "4em",
                      width: "57em",
                    }}
                  >
                    <Col span={8}>
                      <Link className="product-img" to={`/h7`}>
                        <div className="hover-info">
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </div>
                      </Link>
                    </Col>
                    <Col span={8}>
                      <a href="/pdfs/haval_H7_brochure.pdf" target="_blank">
                        <div className="hover-info">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </div>
                      </a>
                    </Col>
                    <Col span={8}>
                      <Link className="product-img" to={`/h7`}>
                        <div className="hover-info">
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Row>
              {/* 7th Slide End */}
            </Slider>
          </div>
          {/* <div
            className="product product-grid product-grid-2 stock m-t-lg-20 p-t-sm-35 m-b-lg-20 "
            style={{ maxWidth: "90em", margin: "auto" }}
          >
            <h1
              style={{
                textAlign: "left",
                fontSize: "30px",
                lineHeight: "0.2em",
                color: "white",
                marginBottom: "1em",
              }}
            >
              Range of Vehicles
            </h1>
            <div className="row">
              <Slider {...settings}>
                {this.state.stock.map((stock, index, props) => {
                  return stock.webVisible &&
                    stock.homeVisible &&
                    stock.megaDealers === "HAVAL" ? (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                      
                        <Link
                          className="product-img"
                          onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/stocko`}
                        >
                          {stock.frontLeftImage ? (
                            <img
                              src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                              alt=""
                            />
                          )}
                        </Link>
                        <div style={{ textAlign: "left" }}>
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em" }}
                          >
                            <Link
                              onClick={() => this.props.fetchSingleStock(stock)}
                              to={`/stocko`}
                            >
                              <span
                                className="f-20  m-t-lg-0 mn-cl"
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                  color: "white",
                                }}
                              >
                                {stock.series}
                                <br />
                              </span>
                              <span
                                className="f-20  m-t-lg-0 mn-cl"
                                style={{
                                  display: "block",
                                  textTransform: "none",
                                  textTransform: "uppercase",
                                  color: "#E00F2F",
                                  fontWeight: 600,
                                }}
                              >
                                <CurrencyFormat
                                  value={stock.price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"From R"}
                                  //format='R### ### ### ###'
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </span>
                            </Link>
                          </h4>
                       
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </Slider>
            </div>
          </div> */}
        </div>
        <div className="wrap-body-inner mobile-view">
          {/* <div> */}
          {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
          {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          {/* </div> */}
          <div
            style={{ height: "48em" }}
          // className="product product-grid product-grid-2 stock  p-t-sm-35 m-b-lg-20 "
          // style={{
          //   maxWidth: "90em",
          //   // backgroundColor: "black",
          //   margin: "auto",
          //   marginTop: "-1.2em",
          //   paddingTop: "3em",
          //   paddingBottom: "1em",
          // }}
          >
            <div
              style={{
                backgroundImage:
                  "url(" + `/images/haval-ranges-background-image.webp` + ")",
                backgroundPosition: "center",
                height: "24em",
                textAlign: "center",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "30px",
                  lineHeight: "0.2em",
                  color: "white",
                  margin: "auto",
                  marginBottom: "1em",
                }}
              >
                Range of Vehicles
              </h1>
              {/* <img
                src={`${process.env.PUBLIC_URL}/images/chery-new/vehicle-view-back.png`}
                alt=''
                style={{ width: "100%", margin: "auto", position: "absolute", top: 0, zIndex: 1 }}
              /> */}
              <Slider {...vehicleRangeSettings}>
                {/* 1st Slide Start */}
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link className="product-img" to={`/jolion`}>
                          <img
                            src={`/images/jolion.webp`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      //
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          JOLION Contemporary Design
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "3em",
                        }}
                      >
                        <Link className="product-img" to={`/jolion`}>
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            style={{ margin: "auto" }}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24} style={{}}>
                        <a
                          href="/pdfs/Haval_Jolion_Brochure_October_2022.pdf"
                          target="_blank"
                        >
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link className="product-img" to={`/jolion`}>
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                {/* 1st Slide End */}
                {/* 2nd Slide Start */}
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link className="product-img" to={`/jolion-s`}>
                          <img
                            src={`/images/jolion-s.webp`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          Jolion S
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "3em",
                        }}
                      >
                        <Link className="product-img" to={`/jolion-s`}>
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            style={{ margin: "auto" }}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a
                          href="/pdfs/haval_jolion-s_brochure.pdf"
                          target="_blank"
                        >
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link className="product-img" to={`/jolion-s`}>
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                {/* 2nd Slide End */}
                {/* 3rd Slide Start */}
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/jolion-hev`}>
                          <img
                            src={`/images/jolion-hev.webp`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          Jolion HEV
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "3em",
                        }}
                      >
                        <Link to={`/jolion-hev`}>
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            style={{ margin: "auto" }}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a
                          href="/pdfs/Haval_Jolion_HEV_Brochure.pdf"
                          target="_blank"
                        >
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link to={`/jolion-hev`}>
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                {/* 3rd Slide End */}
                {/* 4th Slide Start */}
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/h6`}>
                          <img
                            src={`/images/haval-h6.webp`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          HAVAL H6
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "3em",
                        }}
                      >
                        <Link to={`/h6`}>
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            style={{ margin: "auto" }}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a href="/pdfs/haval_H6_brochure.pdf" target="_blank">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link to={`/h6`}>
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                {/* 4th Slide End */}
                {/* 5th Slide Start */}
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link to={`/h6-gt`}>
                          <img
                            src={`/images/haval-h6-gt.webp`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          HAVAL H6 GT
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "3em",
                        }}
                      >
                        <Link to={`/h6-gt`}>
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            style={{ margin: "auto" }}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24}>
                        <a href="/pdfs/H6_GT_Brochure.pdf" target="_blank">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link to={`/h6-gt`}>
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                {/* 5th Slide End */}
                {/* 6th Slide Start */}
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link className="product-img" to={`/h6-hev`}>
                          <img
                            src={`/images/haval-h6-hev.webp`}
                            alt=""
                            style={{
                              width: "100%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      //
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          HAVAL H6 HEV
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "3em",
                        }}
                      >
                        <Link className="product-img" to={`/h6-hev`}>
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            style={{ margin: "auto" }}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24} style={{}}>
                        <a
                          href="/pdfs/Haval-H6-HEV-Specsheet.pdf"
                          target="_blank"
                        >
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link className="product-img" to={`/h6-hev`}>
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                {/* 6th Slide End */}
                {/* 7th Slide Start */}
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                          marginTop: "2em",
                        }}
                      >
                        <Link className="product-img" to={`/h7`}>
                          <img
                            src={`/images/haval-h7.webp`}
                            alt=""
                            style={{
                              width: "49.5%",
                              margin: "auto",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      //
                      textAlign: "center",
                    }}
                  >
                    <Row
                      style={{
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      <Col span={24}>
                        <h1
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            color: "white",
                            letterSpacing: "0.04em",
                            paddingTop: "1em",
                            paddingBottom: "2em",
                          }}
                        >
                          The All New HAVAL H7
                        </h1>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "4em",
                      }}
                    >
                      <Col
                        span={24}
                        style={{
                          marginTop: "3em",
                        }}
                      >
                        <Link className="product-img" to={`/h7`}>
                          <img
                            src={`/images/wheel-img.webp`}
                            width={30}
                            height={30}
                            style={{ margin: "auto" }}
                            alt=""
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            View deals
                          </h4>
                        </Link>
                      </Col>
                      <Col span={24} style={{}}>
                        <a href="/pdfs/haval_H7_brochure.pdf" target="_blank">
                          <img
                            src={`/images/spanner-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Download brochure
                          </h4>
                        </a>
                      </Col>
                      <Col span={24}>
                        <Link className="product-img" to={`/h7`}>
                          <img
                            src={`/images/steering-wheel-img.webp`}
                            width={30}
                            height={30}
                            alt=""
                            style={{
                              margin: "auto",
                              marginTop: "1em",
                            }}
                          />
                          <h4
                            style={{
                              fontSize: "17px",
                              textTransform: "none",
                              color: "white",
                              marginTop: "-0.3em",
                            }}
                          >
                            Book a test drive
                          </h4>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                {/* 7th Slide End */}
              </Slider>
            </div>
          </div>
        </div>
        <BackTop />
      </div>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(Home);
