import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Tabs,
  Badge,
  Image,
  Radio,
  Space,
  Checkbox,
  Form,
  Select,
  Input,
  Modal,
  Collapse,
  Button,
} from "antd";
import CurrencyFormat from "react-currency-format";
import styled from "styled-components";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const text = `
* All calculations, rates and payments shown are GUIDELINES ONLY and are not quotations. Final rates and payments can only be determined by your Finance House once they have assessed all your information (completed in a finance application form). By using this calculator, you hereby INDEMNIFY Mega Theron Group, its holding, associated or partner companies against any loss or liability which you may suffer as a result of using the calculator. All information provided to you on this website is for illustrative purposes only and may not be considered to be ADVICE.
`;
const { Panel } = Collapse;
const StyledCheckbox = styled(Checkbox)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: white !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: red !important;
    border: 0px solid white !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: red!important;
}
.ant-checkbox-checked::after {
  background-color: transparent !important;
  border-color: red !important;
  border: 0px solid red !important;
}
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: transparent !important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid white !important;
  border-radius: 0px;
  color: white!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class FinanceBox extends React.Component {
  state = {
    amountValue: 30000,
    monthsValue: 12,
    depositValue: 0,
    balloonValue: 0,
    interestValue: 7,
  };
  handleAmountChange = (value) => {
    this.setState({ amountValue: value });
  };
  handleDepositChange = (value) => {
    this.setState({ depositValue: value });
  };
  handleMonthsChange = (value) => {
    this.setState({ monthsValue: value });
  };
  handleBalloonChange = (value) => {
    this.setState({ balloonValue: value });
  };
  handleInterestChange = (value) => {
    this.setState({ interestValue: value });
  };

  render() {
    let {
      amountValue,
      depositValue,
      monthsValue,
      balloonValue,
      interestValue,
    } = this.state;

    return (
      <Row style={{ marginBottom: "2em" }}>
        <Col span={14}>
          <h5
            className='m-b-lg-25'
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}>Vehicle Amount</span>
            <span className='color-red' style={{ float: "right" }}>
              <CurrencyFormat
                value={amountValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={(amountValue) => <div>{amountValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={600000}
            minValue={30000}
            value={amountValue}
            onChange={this.handleAmountChange}
          />
          <h5
            className='m-b-lg-25'
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Deposit Amount</span>{" "}
            <span className='color-red' style={{ float: "right" }}>
              <CurrencyFormat
                value={depositValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={(depositValue) => <div>{depositValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={amountValue - 30000}
            minValue={0}
            value={depositValue}
            onChange={this.handleDepositChange}
          />
          <h5
            className='m-b-lg-25'
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Over</span>
            <span className='color-red' style={{ float: "right" }}>
              {monthsValue} Month{monthsValue > 1 && "s"}
            </span>
          </h5>
          <InputRange
            step={12}
            maxValue={96}
            minValue={12}
            value={monthsValue}
            onChange={this.handleMonthsChange}
          />
          <h5
            className='m-b-lg-25'
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Interest Rate</span>
            <span className='color-red' style={{ float: "right" }}>
              {interestValue}%
            </span>
          </h5>
          <InputRange
            step={0.25}
            maxValue={17}
            minValue={7}
            value={interestValue}
            onChange={this.handleInterestChange}
          />
          <h5
            className='m-b-lg-25'
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Balloon Payment</span>
            <span className='color-red' style={{ float: "right" }}>
              {balloonValue}%
            </span>
          </h5>
          <InputRange
            step={1}
            maxValue={8}
            minValue={0}
            value={balloonValue}
            onChange={this.handleBalloonChange}
          />
        </Col>{" "}
        <Col span={10}>
          <Display
            months={monthsValue}
            amount={amountValue}
            deposit={depositValue}
            balloon={balloonValue}
            interest={interestValue}
          />
        </Col>
      </Row>
    );
  }
}
class Display extends React.Component {
  state = {
    APR: 0.05,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateAPR();
    }
  }

  calculateAPR = () => {
    let { amount } = this.props;

    if (1000 < amount && amount < 5000) {
      this.setState({ APR: 0.05 });
    }
    if (5000 < amount && amount < 10000) {
      this.setState({ APR: 0.1 });
    }
    if (10000 < amount && amount < 15000) {
      this.setState({ APR: 0.15 });
    }
    if (15000 < amount && amount < 20000) {
      this.setState({ APR: 0.2 });
    }
  };

  calculateMonthlyRepayment = () => {
    let { amount, months, deposit, interest, balloon } = this.props;
    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      monthlyRepayment < 0 ? "0" : monthlyRepayment
    );

    //let { amount, months, deposit, interest } = this.props;
    //let amountInterestDec = interest / 100;
    //let amountInterestMonth = amountInterestDec / 12;
    //let amountInterest = amountInterestMonth;
    //let montsCalc = Math.pow(1 + amountInterest, months);
    //let monthlyRepayment1 = amountInterest * montsCalc;
    //let monthlyRepayment2 = montsCalc - 1;
    //let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    //let amountDeDeposit = amount - deposit;
    //let monthlyRepaymentTotal = amountDeDeposit * monthlyRepaymentIntersest;
    //let monthlyRepayment = monthlyRepaymentTotal + 94;

    return (
      <h4 className='finance-box-p color-red' style={{ marginTop: "-1em" }}>
        <CurrencyFormat
          value={monthlyRepaymentLast}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"R "}
          //format='R ### ### ### ###'
          renderText={(monthlyRepaymentLast) => (
            <div>{monthlyRepaymentLast}</div>
          )}
        />
      </h4>
    );
  };
  calculateInterest = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentInterest =
      monthlyRepayment * months - (amount - deposit);
    let monthlyRepaymentLast = Math.round(
      monthlyRepaymentInterest < 0 ? "0" : monthlyRepaymentInterest
    );
    return (
      <span>
        <h4 className='finance-box-p color-red'>
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentLast) => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Interest Amount</p>
      </span>
    );
  };
  calculateTotal = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal * months;
    let monthlyRepaymentall = monthlyRepayment;
    let monthlyRepaymentallLast = Math.round(
      monthlyRepaymentall < 0 ? "0" : monthlyRepaymentall
    );
    return (
      <span>
        <h4 className='finance-box-p color-red'>
          <CurrencyFormat
            value={monthlyRepaymentallLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentallLast) => (
              <div>{monthlyRepaymentallLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Total Repayment</p>
      </span>
    );
  };
  calculateAmount = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      amount - deposit < 0 ? "0" : amount - deposit
    );
    return (
      <span>
        <h4 className='finance-box-p color-red'>
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentLast) => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Amount To Finance</p>
      </span>
    );
  };
  percentageAPR = () => {
    return <h4 className='finance-box-p color-red'>{this.state.APR * 100}%</h4>;
  };

  render() {
    return (
      <div className='finance-box-flex'>
        {/*<DisplayChild func={this.percentageAPR()} text='Interest Rate' />*/}
        <DisplayChild
          func={this.calculateMonthlyRepayment()}
          intr={this.calculateInterest()}
          total={this.calculateTotal()}
          fin={this.calculateAmount()}
          text='Monthly Repayment'
        />
      </div>
    );
  }
}

const DisplayChild = ({ func, text, intr, total, fin }) => {
  return (
    <span>
      {func} <p style={{ marginBottom: "0em" }}>{text}</p>
      <br />
      {fin}
      <br />
      {intr}
      <br />
      {total}
    </span>
  );
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
class StockDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      valueRadio: "Speak to a Dealer",
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    console.log(this.props.selectedStock);
    document.documentElement.scrollTop = 0;
  }
  onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadio: e.target.value,
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    var {
      email,
      stockNumber,
      make,
      series,
      modelDesc,
      modelYear,
      valueRadio,
    } = this.props.selectedStock;
    //var { valueRadio } = this.state.valueRadio;
    const MSG = {
      to: { email },
      from: {
        email: "no-reply@mtgroup.co.za",
        name: "Haval Website",
      },
      //to: [  //"ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New Vehicle Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Vehicle Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Vehicle Enquiry from <br /> Mega Theron Group website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                          <strong> Stock Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${stockNumber}<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${make}<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${series}<br />
                          <strong> Derivative </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${modelDesc}<br />
                          <strong> Year </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${modelYear}<br /> 
                          <strong> Request </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.valueRadio}<br />
                 
                      
                       
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2025@HAVAL</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,
        name: "",
        number: "",
        email: "",
      });
    });
  };
  scrollOnclick() {
    // window.location.reload();
    document.documentElement.scrollTop = 0;
  }
  render() {
    console.log(this.props.selectedStock);
    var {
      make,
      series,
      modelDesc,
      modelYear,
      email,
      number,
      regDate,
      extColour,
      intColour,
      retailPriceIncl,
      fuelType,
      capacityCC,
      bodyType,
      transmission,
      engineSize,
      vehicleDrive,
      unladenWeight,
      axles,
      doors,
      cylinders,
      passengers,
      immobiliser,
      dateBuilt,
      extrasAndAccessories,
      comments,
      price,
      branch,
      brand,
      email,
      onSale,
      condition,
      mileage,
      fuelTankSize,
      degreeCamera,
      fourWheelDrive,
      abs,
      driverAssistance,
      airBags,
      aircon,
      alarm,
      mobileCarPlay,
      automaticLights,
      automaticWipers,
      autonomousBraking,
      blindSpotMonitoring,
      bluetooth,
      brakeAssist,
      climateControl,
      cruiseControl,
      daytimeLights,
      electricParkingBrake,
      elctricWindows,
      electronicStability,
      collisionWarning,
      headDisplay,
      heatedSeats,
      hillAssist,
      hillControl,
      keylessEntry,
      laneWarning,
      laneAssist,
      leatherSeats,
      ledLights,
      navigation,
      paddleShift,
      parkControl,
      powerBoot,
      powerSeat,
      remoteStart,
      reverseCamera,
      sunroof,
      touchScreen,
      towbar,
      signRecognition,
      tyreMonitor,
      usbPort,
      voiceRecognition,
      warranty,
      wirelessCharging,
      xenonLights,
      frontLeftImage,
      frontImage,
      frontRightImage,
      rightSideImage,
      wheelsImage,
      backRightImage,
      backImage,
      backLeftImage,
      leftSideImage,
      bootImage,
      frontSeatImage,
      backSeatImage,
      dashPassengerImage,
      dashDriverImage,
      dashDriverSideImage,
      radioImage,
      radioCloseImage,
      gearsImage,
      steeringImage,
      spareKeyImage,
      branchImage,
    } = this.props.selectedStock;
    const images = [
      {
        original: frontLeftImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}` : null,
        thumbnail: frontLeftImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}` : null,
      },
      {
        original: frontImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}` : null,
        thumbnail: frontImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}` : null,
      },
      {
        original: frontRightImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}` : null,
        thumbnail: frontRightImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}` : null,
      },
      {
        original: rightSideImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}` : null,
        thumbnail: rightSideImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}` : null,
      },
      {
        original: backRightImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}` : null,
        thumbnail: backRightImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}` : null,
      },
      {
        original: backImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}` : null,
        thumbnail: backImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}` : null,
      },
      {
        original: backLeftImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}` : null,
        thumbnail: backLeftImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}` : null,
      },
      {
        original: leftSideImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}` : null,
        thumbnail: leftSideImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}` : null,
      },
      {
        original: bootImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}` : null,
        thumbnail: bootImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}` : null,
      },
      {
        original: backSeatImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}` : null,
        thumbnail: backSeatImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}` : null,
      },
      {
        original: dashPassengerImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}` : null,
        thumbnail: dashPassengerImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}` : null,
      },
      {
        original: dashDriverImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}` : null,
        thumbnail: dashDriverImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}` : null,
      },
      {
        original: dashDriverSideImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}` : null,
        thumbnail: dashDriverSideImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}` : null,
      },
      {
        original: radioImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}` : null,
        thumbnail: radioImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}` : null,
      },
      {
        original: radioCloseImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}` : null,
        thumbnail: radioCloseImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}` : null,
      },
      {
        original: gearsImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}` : null,
        thumbnail: gearsImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}` : null,
      },
      {
        original: steeringImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}` : null,
        thumbnail: steeringImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}` : null,
      },
      {
        original: spareKeyImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}` : null,
        thumbnail: spareKeyImage ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}` : null,
      },
    ];
    return (
      <div className='wrap-body-inner' style={{ margin: "auto", marginTop: "7em", maxWidth: "90em" }}>
        <div>
          <div className='row'>
            {/* <div className='col-lg-6' style={{ position: "fixed", zIndex: 9, marginTop: "0.5em" }}>
              <ul className='ht-breadcrumb pull-left'>
                <li className='home-act'>
                  <Link to={"/stock"}>
                    <i className='fa fa-arrow-left' aria-hidden='true'></i>
                  </Link>
                </li>
                <li className='active'>{make}</li>
              </ul>
            </div> */}
            {/* <div className='col-lg-6'>
              <Link
                to={"/stock"}
                style={{ width: 20 }}
                className='col-sm-1 col-md-1 col-lg-1 p-lg-0 ht-btn ht-btn-lightgray pull-right'
              >
                <i
                  style={{ marginLeft: 7 }}
                  className='fa fa-long-arrow-left center'
                />
              </Link>
            </div> */}
          </div>
        </div>
        <section className=' m-t-xs-0' style={{ margin: "auto", marginTop: "0em", maxWidth: "100em" }}>


          <div className='col-sm-12 col-md-12 desktop-view' style={{ height: "9em", marginTop: "1em" }}>
            <Badge.Ribbon text={condition} color='#CD2934' style={{ marginTop: "-0.5em", }}>
              <Row
                gutter={16}
                style={{
                  //padding: "2em",
                  // boxShadow: "2px 2px 10px 2px #ecececd7",
                  borderRadius: "1.5em",
                }}
              >
                <Col sm={24} md={18}>
                  <Card bordered={false} style={{ padding: 0, backgroundColor: "transparent", }}>
                    <h4
                      style={{
                        color: "white",
                        paddingTop: "0em",
                        paddingBottom: "0em",
                        paddingRight: "0.2em",
                        marginTop: "-1em",
                      }}
                      className='product-name '
                    //style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <span className='f-40'>
                        {make} {series} <br />
                        <p className='f-20' style={{ color: "white" }}>{modelDesc}</p>
                      </span>
                    </h4>{" "}
                  </Card>
                </Col>
                <Col sm={24} md={6}>
                  <Card bordered={false} style={{ padding: 0, backgroundColor: "transparent" }}>
                    <div
                      style={{
                        textAlign: "right",
                        // height: "8.6em",
                      }}
                    >
                      <div className='f-12 m-t-lg-5  mn-cl' style={{ color: "white" }}>
                        Price
                      </div>
                      <div className='f-40 m-t-lg-0 mn-cl' style={{ color: "white" }}>
                        <CurrencyFormat
                          value={price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"R"}
                          //format='R ### ### ### ###'
                          renderText={(value) => (
                            <div style={{ lineHeight: 1 }}>{value}</div>
                          )}
                        />
                      </div>
                      {/*<p className='f-20 f-weight-500 m-b-lg-0'>
                          <CurrencyFormat
                            value={3650}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"R"}
                            suffix={" pm"}
                            //format='R #### #### pm*'
                            renderText={(value) => <span>{value}</span>}
                          />
                        </p>{" "}*/}
                      {/*<div className='f-15 m-t-lg-0 m-b-lg-0 m-t-lg-0'>
                          RATE CALCULATION
                        </div>*/}
                    </div>
                  </Card>
                </Col>

              </Row>{" "}
            </Badge.Ribbon>
          </div>
          <div className='col-sm-12 col-md-12 mobile-view' style={{ height: "9em", marginTop: "1em" }}>

            <Row

              style={{
                //padding: "2em",
                // boxShadow: "2px 2px 10px 2px #ecececd7",
                borderRadius: "1.5em",
              }}
            >
              <Col sm={24} md={18} style={{ width: "100vw" }}>
                <Card bordered={false} style={{ padding: 0, backgroundColor: "transparent", textAlign: "center" }}>
                  <h4
                    style={{
                      color: "white",
                      paddingTop: "0em",
                      paddingBottom: "0em",
                      paddingRight: "0.2em",
                      marginTop: "-1em",
                    }}
                    className='product-name '
                  //style={{ marginBottom: "0em", marginTop: "-1em" }}
                  >
                    <span className='f-40'>
                      {make} {series} <br />
                      <p className='f-20' style={{ color: "white" }}>{modelDesc}</p>
                    </span>
                  </h4>{" "}
                  <div
                    style={{
                      textAlign: "center",
                      // height: "8.6em",
                    }}
                  >
                    <div className='f-12 m-t-lg-5  mn-cl' style={{ color: "white" }}>
                      Price
                    </div>
                    <div className='f-40 m-t-lg-0 mn-cl' style={{ color: "white" }}>
                      <CurrencyFormat
                        value={price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        //format='R ### ### ### ###'
                        renderText={(value) => (
                          <div style={{ lineHeight: 1 }}>{value}</div>
                        )}
                      />
                    </div></div>
                </Card>


              </Col>

            </Row>{" "}

          </div>
          <div
            className='row m-t-lg-50'
            style={{ marginBottom: window.isPhone ? "0em" : "50px" }}
          >
            <div className='col-md-12' >
              <Tabs defaultActiveKey='1' onChange={callback} tabPosition="top" style={{ color: "white" }} onTabClick={this.scrollOnclick}>
                <TabPane tab='Showroom' key='1' >
                  <div className='row m-t-lg-20 m-b-lg-10 desktop-view'>
                    <div className='product_detail no-bg p-lg-0'>
                      <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='product-img-lg'>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                              {frontLeftImage ? (
                                <Image
                                  className='product-details-img'
                                  src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}`}
                                  alt=''
                                />
                              ) : null}
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                              {frontImage ? (
                                <Image
                                  className='product-details-img'
                                  src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}`}
                                  alt=''
                                />
                              ) : null}
                            </div>
                            {frontRightImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}`}
                                alt=''
                              />
                            ) : null}
                            {rightSideImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}`}
                                alt=''
                              />
                            ) : null}
                            {backRightImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}`}
                                alt=''
                              />
                            ) : null}
                            {backImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}`}
                                alt=''
                              />
                            ) : null}
                            {backLeftImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}`}
                                alt=''
                              />
                            ) : null}
                            {leftSideImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}`}
                                alt=''
                              />
                            ) : null}
                            {bootImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}`}
                                alt=''
                              />
                            ) : null}
                            {frontSeatImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontSeatImage}`}
                                alt=''
                              />
                            ) : null}
                            {backSeatImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}`}
                                alt=''
                              />
                            ) : null}
                            {dashPassengerImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}`}
                                alt=''
                              />
                            ) : null}
                            {dashDriverImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}`}
                                alt=''
                              />
                            ) : null}
                            {dashDriverSideImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}`}
                                alt=''
                              />
                            ) : null}
                            {radioImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}`}
                                alt=''
                              />
                            ) : null}
                            {radioCloseImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}`}
                                alt=''
                              />
                            ) : null}
                            {gearsImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}`}
                                alt=''
                              />
                            ) : null}
                            {steeringImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}`}
                                alt=''
                              />
                            ) : null}
                            {spareKeyImage ? (
                              <Image
                                className='product-details-img'
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}`}
                                alt=''
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className='row  mobile-view'>

                    <div className='row' style={{ marginBottom: "2em", textAlign: "center" }}>


                      {frontLeftImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}`}
                          alt=''
                        />
                      ) : null}


                      {frontImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}`}
                          alt=''
                        />
                      ) : null}

                      {frontRightImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}`}
                          alt=''
                        />
                      ) : null}
                      {rightSideImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}`}
                          alt=''
                        />
                      ) : null}
                      {backRightImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}`}
                          alt=''
                        />
                      ) : null}
                      {backImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}`}
                          alt=''
                        />
                      ) : null}
                      {backLeftImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}`}
                          alt=''
                        />
                      ) : null}
                      {leftSideImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}`}
                          alt=''
                        />
                      ) : null}
                      {bootImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}`}
                          alt=''
                        />
                      ) : null}
                      {frontSeatImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontSeatImage}`}
                          alt=''
                        />
                      ) : null}
                      {backSeatImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}`}
                          alt=''
                        />
                      ) : null}
                      {dashPassengerImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}`}
                          alt=''
                        />
                      ) : null}
                      {dashDriverImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}`}
                          alt=''
                        />
                      ) : null}
                      {dashDriverSideImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}`}
                          alt=''
                        />
                      ) : null}
                      {radioImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}`}
                          alt=''
                        />
                      ) : null}
                      {radioCloseImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}`}
                          alt=''
                        />
                      ) : null}
                      {gearsImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}`}
                          alt=''
                        />
                      ) : null}
                      {steeringImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}`}
                          alt=''
                        />
                      ) : null}
                      {spareKeyImage ? (
                        <Image
                          className='product-details-img'
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}`}
                          alt=''
                        />
                      ) : null}


                    </div>
                  </div>{" "}

                </TabPane>
                <TabPane tab='Overview' key='2'>
                  <div className='m-b-lg-30'>
                    <div className='heading-1 '>
                      <h3 className='f-18'>OverView</h3>
                    </div>
                    {comments ? (
                      <div className='m-b-lg-30 bg-gray-fa bg1-gray-2 p-lg-30 p-xs-15' style={{ backgroundColor: "black", color: "white" }}>
                        <p style={{ color: "white" }}>{comments}</p>
                      </div>
                    ) : null}
                    <ul className='product_para-1'>
                      {bodyType ? (
                        <li>
                          <span>Body Type :</span>
                          {bodyType}
                        </li>
                      ) : null}
                      {modelYear ? (
                        <li>
                          <span>Model Year :</span>
                          {modelYear}
                        </li>
                      ) : null} {branch ? (
                        <li>
                          <span>Branch :</span>
                          {branch}
                        </li>
                      ) : null} {fuelType ? (
                        <li>
                          <span>Fuel Type :</span>
                          {fuelType}
                        </li>
                      ) : null} {transmission ? (
                        <li>
                          <span>Transmission :</span>
                          {transmission}
                        </li>
                      ) : null}
                    </ul>

                  </div>


                </TabPane>
                <TabPane tab='General' key='3'>
                  <div className='heading-1 '>
                    <h3 className='f-18'>General</h3>
                  </div>
                  <ul className='product_para-1'>
                    {make ? (
                      <li>
                        <span>Make :</span>
                        {make}
                      </li>
                    ) : null}
                    {series ? (
                      <li>
                        <span>Model :</span>
                        {series}
                      </li>
                    ) : null}
                    {bodyType ? (
                      <li>
                        <span>Body :</span>
                        {bodyType}
                      </li>
                    ) : null}
                    {fuelType ? (
                      <li>
                        <span>Fuel :</span>
                        {fuelType}
                      </li>
                    ) : null}
                    {modelDesc ? (
                      <li>
                        <span>Engine :</span>
                        {modelDesc}
                      </li>
                    ) : null}
                    {transmission ? (
                      <li>
                        <span>Transmission :</span>
                        {transmission}
                      </li>
                    ) : null}
                    {extColour ? (
                      <li>
                        <span>Color :</span>
                        {extColour}
                      </li>
                    ) : null}
                    {mileage ? (
                      <li>
                        <span>Mileage :</span>
                        {mileage}
                      </li>
                    ) : null}
                  </ul>
                </TabPane>
                <TabPane tab='Engine' key='4'>
                  <div className='heading-1 '>
                    <h3 className='f-18'>Engine</h3>
                  </div>
                  <ul className='product_para-1'>
                    {engineSize ? (
                      <li>
                        <span>Engine Size :</span>
                        {engineSize}
                      </li>
                    ) : null}
                    {vehicleDrive ? (
                      <li>
                        <span>Vehicle Drive :</span>
                        {vehicleDrive}
                      </li>
                    ) : null}
                    {axles ? (
                      <li>
                        <span>Axles :</span>
                        {axles}
                      </li>
                    ) : null}
                    {cylinders ? (
                      <li>
                        <span>Cylinders :</span>
                        {cylinders}
                      </li>
                    ) : null}
                    {fuelType ? (
                      <li>
                        <span>Fuel type :</span>
                        {fuelType}
                      </li>
                    ) : null}
                    {fuelTankSize ? (
                      <li>
                        <span>Fuel capacity :</span>
                        {fuelTankSize}
                      </li>
                    ) : null}
                  </ul>
                </TabPane>
                <TabPane tab='Comfort' key='5'>
                  <div className='heading-1 '>
                    <h3 className='f-18'>Comfort</h3>
                  </div>
                  <ul className='product_para-1'>
                    {passengers ? (
                      <li>
                        <span>Seats :</span>
                        {passengers}
                      </li>
                    ) : null}
                    {doors ? (
                      <li>
                        <span>No of doors :</span>
                        {doors}
                      </li>
                    ) : null}
                  </ul>
                </TabPane>
                <TabPane tab='Extras And Accessories' key='6'>
                  <div className='heading-1 '>
                    <h3 className='f-18'>Extras And Accessories</h3>
                  </div>
                  <div className='desktop-view'>
                    <Row>
                      {degreeCamera === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            360 Degree Camera
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {fourWheelDrive === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            4WD
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {abs === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            ABS
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {driverAssistance === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Driver Assistance System
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {airBags === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Airbags
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {aircon === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Aircon
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {alarm === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Alarm
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {mobileCarPlay === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Apple CarPlay / Android Auto
                          </StyledCheckbox>
                        </Col>
                      ) : null}

                      {automaticLights === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Automatic Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {automaticWipers === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Automatic Wipers
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {autonomousBraking === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Auto Emergency Braking
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {blindSpotMonitoring === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Blind Spot Monitoring
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {bluetooth === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Bluetooth
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {brakeAssist === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Brake Assist
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {climateControl === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Climate Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {cruiseControl === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Cruise Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {daytimeLights === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Daytime Running Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {electricParkingBrake === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Electric Parking Brake
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {elctricWindows === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Electric Windows
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {electronicStability === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Electronic Stability System
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {collisionWarning === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Forward Collision Warning
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {headDisplay === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Head-up Display
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {heatedSeats === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Heated / Ventilated Seats
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {hillAssist === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Hill Assist
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {hillControl === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Hill Descent Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {keylessEntry === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Keyless Entry
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {laneWarning === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Lane Departure Warning
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {laneAssist === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Lane Keeping Assist
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {leatherSeats === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Leather Seats
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {ledLights === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            LED Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {navigation === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Navigation
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {paddleShift === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Paddle Shift
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {parkControl === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Park Distance Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {powerBoot === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Power Boot
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {powerSeat === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Power Seat(s)
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {remoteStart === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Remote Start
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {reverseCamera === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Reverse Camera
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {sunroof === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Sunroof
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {touchScreen === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Touch Screen System
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {towbar === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Towbar
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {signRecognition === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Traffic Sign Recognition
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {tyreMonitor === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Tyre Pressure Monitor
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {usbPort === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            USB Port(s)
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {voiceRecognition === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Voice Recognition
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {warranty === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Warranty / Service Plan
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {wirelessCharging === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Wireless Charging
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {xenonLights === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Xenon Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                  <div className='mobile-view'>
                    <Row>
                      {degreeCamera === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            360 Degree Camera
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {fourWheelDrive === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            4WD
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {abs === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            ABS
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {driverAssistance === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Driver Assistance System
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {airBags === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Airbags
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {aircon === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Aircon
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {alarm === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Alarm
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {mobileCarPlay === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Apple CarPlay / Android Auto
                          </StyledCheckbox>
                        </Col>
                      ) : null}

                      {automaticLights === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Automatic Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {automaticWipers === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Automatic Wipers
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {autonomousBraking === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Auto Emergency Braking
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {blindSpotMonitoring === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Blind Spot Monitoring
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {bluetooth === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Bluetooth
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {brakeAssist === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Brake Assist
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {climateControl === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Climate Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {cruiseControl === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Cruise Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {daytimeLights === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Daytime Running Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {electricParkingBrake === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Electric Parking Brake
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {elctricWindows === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Electric Windows
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {electronicStability === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Electronic Stability System
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {collisionWarning === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Forward Collision Warning
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {headDisplay === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Head-up Display
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {heatedSeats === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Heated / Ventilated Seats
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {hillAssist === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Hill Assist
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {hillControl === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Hill Descent Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {keylessEntry === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Keyless Entry
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {laneWarning === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Lane Departure Warning
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {laneAssist === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Lane Keeping Assist
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {leatherSeats === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Leather Seats
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {ledLights === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            LED Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {navigation === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Navigation
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {paddleShift === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Paddle Shift
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {parkControl === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Park Distance Control
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {powerBoot === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Power Boot
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {powerSeat === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Power Seat(s)
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {remoteStart === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Remote Start
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {reverseCamera === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Reverse Camera
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {sunroof === true ? (
                        <Col span={8}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Sunroof
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {touchScreen === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Touch Screen System
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {towbar === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Towbar
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {signRecognition === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Traffic Sign Recognition
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {tyreMonitor === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Tyre Pressure Monitor
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {usbPort === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            USB Port(s)
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {voiceRecognition === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Voice Recognition
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {warranty === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Warranty / Service Plan
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {wirelessCharging === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Wireless Charging
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                      {xenonLights === true ? (
                        <Col span={12}>
                          <StyledCheckbox checked={true} onChange={onChange}>
                            Xenon Lights
                          </StyledCheckbox>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                </TabPane>{" "}
                {/* {window.isPhone ? null : (
                  <TabPane tab='Finance Calculator' key='7'>
                    <div className='col-md-5 col-lg-12'>
                      <div className='heading-1 '>
                        <h3 className='f-18'>Finance Calculator</h3>
                        <FinanceBox />{" "}
                        <Collapse ghost>
                          <Panel header='Disclaimer' key='1'>
                            <p>{text}</p>
                          </Panel>
                        </Collapse>
                      </div>
                    </div>
                  </TabPane>
                )} */}
              </Tabs>
            </div>


          </div>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='col-sm-12 col-md-12 col-lg-12' style={{ backgroundColor: "black" }}>
              <div className='heading-1'>
                <h3 className="f-18">
                  Dealer Infomation
                </h3>
              </div>
              <span

                className='bg-gray-fa bg1-gray-2 p-lg-15 text-center m-b-lg-20 display-block'
                style={{ backgroundColor: "transparent" }}
              >
                {branchImage ? (
                  <img
                    style={{ width: "18em" }}
                    src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${branchImage}`}
                    alt=''
                  />
                ) : (
                  <img
                    style={{ width: "18em" }}
                    src={
                      brand === "Mega Theron Group"
                        ? `/images/logo.png`
                        : brand === "Mega Theron Traders"
                          ? `/images/mega-theron-traders.png`
                          : brand === "Kia"
                            ? `/images/kia-image.png`
                            : brand === "Mahindra"
                              ? `/images/mahindra-image.png`
                              : brand === "Chery"
                                ? `/images/chery-image.png`
                                : brand === "Gwm"
                                  ? `/images/gwm-image.png`
                                  : brand === "Haval"
                                    ? `/images/haval-image.png`
                                    : brand === "JAC"
                                      ? `/images/jac-image.png`
                                      : `/images/logo.png`
                    }
                    alt=''
                  />
                )}
              </span>
              <h4 className='p-t-lg-0'>
                {brand || branch ? (
                  <span >
                    {brand}, {branch}
                  </span>
                ) : null}
              </h4>
              <div className='clearfix'></div>
              <ul className='list-default m-t-lg-0' style={{ color: "white" }}>
                {/*<li>
                  <i className="fa fa-user-circle-o m-r-lg-10  icon"></i>Mr.
                  Janhn
                </li>*/}
                {number ? (
                  <li>
                    {" "}
                    <a href={"tel:" + number}>
                      <i className='fa fa-phone m-r-lg-10 icon'></i>
                      {number}
                    </a>
                  </li>
                ) : null}
                {email ? (
                  <li>
                    <a href={"mailto:" + email}>
                      <i className='fa fa-envelope-o m-r-lg-10 icon'></i>
                      {email}
                    </a>
                  </li>
                ) : null}
                {/*{address ?
                <li>
                  <i className='fa fa-home m-r-lg-10 icon'></i>{address}
                </li>: null}*/}
                {/*{number ?
                <li className='view-map'>
                  <i className='fa fa-map-marker m-r-lg-10 icon'></i>Click to
                  view Map
                </li>
                : null}*/}
                {/*{website ?
                <li>
                  <a href='https://www.megatheron.co.za/'>
                    <i className='fa fa-globe m-r-lg-10 icon'></i>
                    {website}
                  </a>
                </li>: null}*/}
              </ul>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='m-t-lg-30' >
              <div className='banner-item banner-2x banner-bg-10 color-inher p-lg-30' style={{ backgroundColor: "black" }}>
                <h3 className='f-weight-300'>
                  <strong style={{ lineHeight: "1em", }}>
                    Make Vehicle Enquiry
                  </strong>
                </h3>
                <p style={{ margin: "auto" }}>
                  Speak to our stock specialists, book your test drive or
                  viewing today.
                </p>
              </div>
              <div className='bg1-gray-2'>
                <Form
                  style={{ maxWidth: "80vw", margin: "auto" }}
                  id='book-service'
                  onFinish={this.handleSubmit}
                  initialValues={{
                    remember: true,
                  }}
                  name='wrap'
                  labelCol={{ flex: "110px" }}
                  labelAlign='left'
                  labelWrap
                  wrapperCol={{ flex: 1 }}
                  colon={false}
                >
                  <div className='m-b-lg-20' style={{ maxWidth: "40em", margin: "auto" }}>
                    <div className='row'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <Form.Item
                          name='name'
                          rules={[{ required: true, message: false }]}
                          hasFeedback
                        >
                          <StyledInput placeholder='Name' />
                        </Form.Item>
                      </div>

                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <Form.Item
                          name='number'
                          rules={[
                            {
                              //max:10,
                              //min:10,
                              required: true,
                              message: false,
                              //type: "number",
                            },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder='Number' />
                        </Form.Item>
                      </div>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <Form.Item
                          name='email'
                          rules={[
                            { required: true, message: false, type: "email" },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder='Email' />
                        </Form.Item>
                      </div>

                      <div className='text-center col-sm-12 col-md-12 col-lg-12'>
                        <Form.Item
                          name='book'
                          rules={[{ required: false, message: false }]}
                        >
                          <Radio.Group
                            onChange={this.onChangeRadio}
                            value={this.state.valueRadio}
                          >
                            <Space
                              direction='horizontal'
                              style={{
                                display: window.isPhone ? "grid" : "",
                              }}
                            >
                              <Radio style={{ color: "white" }} value='Speak to a Dealer'>
                                Speak to a Dealer
                              </Radio>
                              <Radio style={{ color: "white" }} value='Book a Test Drive'>
                                Book a Test Drive
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className='col-lg-12 m-t-lg-12 text-center'>
                        <Form.Item>
                          <Button
                            style={{
                              fontFamily: "Arial",
                              border: "1px solid #CD2934",
                              fontSize: "15px",
                              fontWeight: 600,
                              borderRadius: "0em",
                              backgroundColor: "#CD2934",
                              color: "white",
                              height: "55px",
                              width: "100%",
                              paddingLeft: "3em",
                              paddingRight: "3em",
                              textShadow: "none",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                            type='primary'
                            htmlType='submit'
                          >
                            Send Message
                          </Button>{" "}
                        </Form.Item>{" "}
                        <p
                          style={{
                            color: "black",
                            //marginBottom: "2.5em",
                            marginTop: "0em",
                            fontSize: "13px",
                            lineHeight: "1.5em",
                          }}
                        >
                          By submitting this form, you agree to our{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link style={{ color: "black" }} to='/terms'>
                              Terms of Use
                            </Link>
                          </span>{" "}
                          and{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              to='/privacy-policy'
                            >
                              Privacy Statement
                            </Link>
                          </span>
                          .
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>{" "}
          </div>
        </section>{" "}
        <Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='Logo'
            style={{ width: "65%", margin: "auto" }}
          />
          <p
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for contacting
            <br />
            <span style={{ color: "rgb(214, 54, 41)" }}>
              The Mega Theron Group.
            </span>{" "}
            <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              Our vehicle specialist will be in touch shortly.
            </span>{" "}
            <br />
          </p>{" "}
          <br />
        </Modal>
      </div>
    );
  }
}
function mapStateToProps({ selectedStock }) {
  return { selectedStock };
}

export default connect(mapStateToProps)(withRouter(StockDetails));
